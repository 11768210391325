import Cookies from 'js-cookie';
import { useState } from 'react';

import { COOKIES } from '@lib/constants';

export const useGaCookie = (
  experimentId: string
): Array<string | undefined> => {
  const getCookie = () => {
    const cook = Cookies.get(`${COOKIES.abTestingCookie}.${experimentId}`);
    return cook;
  };

  const [gaCookie, updateGaCookie] = useState(getCookie());
  // Default to 0, as that is baseline variant, i.e. no change
  let variant = '0';

  if (gaCookie) {
    variant = gaCookie.split('.')[1];
  }

  return [gaCookie, variant];
};
