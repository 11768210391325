/**
 * The entire header section, comprised of smaller components
 * MobileMenu is here because we want it to show up across the whole thing, but it's trigger
 * is in <Search/>, which is the hamburger icon is to appear
 */

import dynamic from 'next/dynamic';
import React, { ReactElement, useContext, useEffect } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { QuickCart } from '@components/Cart/QuickCart/QuickCart';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Quicklinks } from '@components/Header/Quicklinks';
import { RecentlyViewed } from '@components/RecentlyViewed/RecentlyViewed';
import { useGaCookie } from '@hooks/useGaCookie';
import { useScroll } from '@hooks/useScroll';
import { useWindowSize } from '@hooks/useWindowSize';
import { mergeStyles } from '@lib/styles';

import { InfoNavbar, ShopBy } from '.';

const MobileMenuDynamic = dynamic(() =>
  import('../MobileMenu/MobileMenu').then((mod) => mod.MobileMenu)
);

const MenuDynamic = dynamic(() =>
  import('./Menu/Menu').then((mod) => mod.Menu)
);

const MegaMenuDynamic = dynamic(
  () => import('./MegaMenu/MegaMenu').then((mod) => mod.MegaMenu)
  // {
  //   loading: () => <div className="hidden h-12.5 bg-white xl:block" />,
  // }
);

function SlidingHeader({
  width,
  showUnifiedMenu,
}: {
  width: number | undefined;
  showUnifiedMenu: boolean;
}) {
  const {
    headerHeight: [headerHeight, setHeaderHeight],
    headerScrollClass: [headerScrollClass, setHeaderScrollClass],
  } = useContext<UIContextInterface>(UIContext);

  const { x, y, lastX, lastY } = useScroll();

  useEffect(() => {
    let mounted = true;
    // get the height of the header on load
    // This function get header height and set it to state
    const getHeaderHeight = () => {
      const newHeight = (document.getElementById('header') as HTMLElement)
        .offsetHeight;

      setHeaderHeight(newHeight);
    };

    getHeaderHeight();

    const newClass =
      headerHeight && y > lastY && y > headerHeight ? 'hide' : 'show';

    if (mounted && newClass !== headerScrollClass)
      setHeaderScrollClass(newClass);

    return function cleanup() {
      mounted = false;
    };
  }, [
    x,
    y,
    lastX,
    lastY,
    headerScrollClass,
    headerHeight,
    setHeaderHeight,
    setHeaderScrollClass,
  ]);

  return (
    <header
      id="header"
      className={mergeStyles(
        showUnifiedMenu ? '' : 'xl:min-h-[201px]',
        `${headerScrollClass} full-bleed relative left-0
      right-0 top-0 z-1 min-h-[99px] transition-all
      duration-200 ease-in-out [&.hide]:sticky
      [&.hide]:-translate-y-full [&.show]:sticky [&.show]:translate-y-0
      `
      )}
    >
      <BulkErrorBoundary location="Header/InfoNavbar">
        <InfoNavbar />
      </BulkErrorBoundary>

      <BulkErrorBoundary location="Header/Quicklinks">
        <Quicklinks />
      </BulkErrorBoundary>

      {/* Need a placeholder for desktop megamenu while it is dynamically loaded */}
      {!showUnifiedMenu && !width && (
        <div className="hidden h-12.5 bg-white xl:block" />
      )}
      {!showUnifiedMenu && width && width >= 1280 && (
        <BulkErrorBoundary location="Header/MegaMenuDynamic">
          <div style={{ height: '50px' }}>
            <MegaMenuDynamic />
          </div>
        </BulkErrorBoundary>
      )}

      {!showUnifiedMenu && <ShopBy />}
    </header>
  );
}

export function Header(): ReactElement {
  const {
    showUnifiedMenu: [showUnifiedMenu, setShowUnifiedMenu],
  } = useContext<UIContextInterface>(UIContext);
  const { width } = useWindowSize();
  /*
    Menu split test values:
      0: old mega/mobile menu
      1: unified no thumbnails
      2: unified w/ thumbnails
  */
  const gaCookie = useGaCookie('unified-menu-ab-aug-2024');
  // for split testing to show unified menu v. old mega/mobile menus
  useEffect(() => {
    if (gaCookie[1]) setShowUnifiedMenu(+gaCookie[1] !== 0);
  }, [gaCookie]);

  return (
    <BulkErrorBoundary location="Header">
      <QuickCart />
      <RecentlyViewed />
      {!showUnifiedMenu && width && width < 1280 && <MobileMenuDynamic />}
      {showUnifiedMenu && <MenuDynamic />}
      <SlidingHeader width={width} showUnifiedMenu={showUnifiedMenu} />
    </BulkErrorBoundary>
  );
}
